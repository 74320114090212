<template>
  <v-card flat>
    <v-dialog v-model="showImage" fullscreen>
      <v-img
        @click="showImage=false"
        :src="showImageUrl"
        max-height="100%"
      />
    </v-dialog>
    <v-card-actions>
      <v-btn
        :loading="isLoading"
        @click="generateMenu">
        Generate Menu
      </v-btn>
      <v-spacer/>
      <router-link :to="{name:'MenuEdit'}">edit menu</router-link>
      <v-spacer/>
      <v-menu
        ref="menu"
        v-model="dateMenu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            label="Picker in menu"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
          :allowed-dates="isSunday"
        >
          <v-spacer></v-spacer>
          <!--          <v-btn-->
          <!--            text-->
          <!--            color="primary"-->
          <!--            @click="menu = false"-->
          <!--          >-->
          <!--            Cancel-->
          <!--          </v-btn>-->
          <!--          <v-btn-->
          <!--            text-->
          <!--            color="primary"-->
          <!--            @click="$refs.menu.save(date)"-->
          <!--          >-->
          <!--            OK-->
          <!--          </v-btn>-->
        </v-date-picker>
      </v-menu>

    </v-card-actions>
    <v-card-text v-if="error">
      <v-alert type="warning">{{ error }}</v-alert>
    </v-card-text>
    <v-card-text>
      <v-row>
        <!--        <v-col cols="3">-->
        <!--          <v-date-picker-->
        <!--            v-model="date"-->
        <!--            :allowed-dates="isSunday"-->
        <!--          />-->
        <!--        </v-col>-->
        <v-col v-if="menu">
          <!--          <h1>Menu for week {{ date }}</h1>-->
          <v-btn-toggle v-model="filter">
            <v-btn
              v-for="stream of Object.keys(menu)"
              v-bind:key="stream"
              :value="stream">{{ stream }}
            </v-btn>
          </v-btn-toggle>
          <!--          <pre>{{ Object.keys(menu.full) }}</pre>-->
          <v-card
            v-for="({menu,updated_at},stream) of activeMenu"
            v-bind:key="stream"
          >
            <v-card-title><h1>{{ getStreamName(stream) }} ({{ stream }})</h1></v-card-title>
            <v-card-text v-for="({date,meals,products},index) of menu" v-bind:key="index">
              <h2 v-if="date!=='any'">Meals on {{ dateFormatted(date) }}</h2>
              <h2 v-if="date==='any'">Chef Choice Meals</h2>
              <v-card class="my-4" v-for="(meal,mealIndex) of (products||meals)" v-bind:key="mealIndex">
                <v-card-title>
                  {{ date === 'any' ? '' : dateFormatted(date) }}
                  {{ meal.tod }}
                  <span v-if="meal.deliveryDate">(delivery {{ dateFormatted(meal.deliveryDate) }})</span>
                  <v-spacer/>
                  <v-chip outlined>{{ meal.stream }}</v-chip>
                  <br/>


                </v-card-title>
                <v-card-text>
                  <h3>
                    <template v-if="meal.price">{{ formatCurrency(meal.price) }}</template>
                    {{ meal.name }} (M{{ meal.id }})
                  </h3>
                  <p>{{ meal.ingredients }}</p>
                  <v-row>
                    <v-col
                      cols="3"
                      v-for="(url,slot) of (meal.photos||{})" v-bind:key="slot">
                      <h4>{{ slot }}</h4>
                      <v-img
                        @click="showImage=true; showImageUrl=url"
                        :src="url"
                      />
                    </v-col>
                  </v-row>
                  <v-chip-group column>
                    <v-chip v-for="tag of ((meal.tags||[]))" v-bind:key="tag">{{ tag }}</v-chip>
                    <v-chip outlined v-for="tag of (Object.values(meal.food_tags||{}))" v-bind:key="tag">{{ tag }}</v-chip>
                  </v-chip-group>
                </v-card-text>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import api from "@/api";
import {dateFormatted, formatCurrency} from "@/store/utils";
import urlState from "@/router/urlState";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "Menu",
  mixins: [urlState],
  data() {
    return {
      date: moment().startOf('week').add(2, 'weeks').format(moment.HTML5_FMT.DATE),
      chooseWeek: null,
      menu: null,
      showImage: null,
      showImageUrl: null,
      filter: '',
      error: null,
      isLoading: null,
      dateMenu: null
    }
  },
  mounted() {
    this.syncToUrl({
      param: 'date', urlParam: 'date', initFromRoute: true,
      // parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'filter', urlParam: 'stream', initFromRoute: true,
      // parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.fetchStreams();
    this.fetchMenu(this.date);
  },
  watch: {
    date(d) {
      return this.fetchMenu(d);
    }
  },
  methods: {
    ...mapActions(['fetchStreams']),
    formatCurrency,
    dateFormatted,
    isSunday(d) {
      return moment(d).day() === 0;
    },
    fetchMenu(d) {
      api.get(`v2/menu/${d}`)
        .then(({data}) => {
          this.error = '';
          if (!data) {
            this.error = 'no menu found for this date';
          }
          this.menu = data;
        })
    },
    generateMenu() {
      this.isLoading = true;
      return api
        .post(`v2/menu/${this.date}`)
        .then(({data}) => {
          this.error = '';
          if (!data) {
            this.error = 'no menu found for this date';
          }
          this.menu = data;
        })
        .finally(() => this.isLoading = false);
    }
  },
  computed: {
    ...mapState(['streams']),
    ...mapGetters(['getStreamName']),
    activeMenu() {
      if (this.filter) {
        const val = this.menu[this.filter];
        const result = {};
        result[this.filter] = val;
        return result;
      } else {
        return this.menu;
      }
    }
  }
}
</script>

<style scoped>

</style>